/* @import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap'); */

@font-face {
    font-family: "Rogan";
    /*Can be any text*/
    src: url("./assets/fonts/Rogan-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* For WebKit-based browsers (Chrome, Safari) */
.thin-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(232, 186, 115, 1);
    border-radius: 1000px;
}

.thin-scrollbar::-webkit-scrollbar-track:not(#map-content) {
    border-radius: 1000px;
}

.thin-scrollbar::-webkit-scrollbar {
    background: rgba(33, 35, 36, 1);
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 1000px;
}

/* For Firefox */
.hide-scrollbar {
    scrollbar-width: auto;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(232, 186, 115, 1);
    border-radius: 1000px;
}

.hide-scrollbar::-webkit-scrollbar-track:not(#map-content) {
    border-radius: 1000px;
}

.hide-scrollbar::-webkit-scrollbar {
    background: rgba(33, 35, 36, 1);
    display: block;
    width: 0px;
    height: 0px;
    border-radius: 1000px;
}

.hide-scrollbar {
    scrollbar-width: 0px;
}

* {
    box-sizing: border-box;
}

@keyframes zoom-in {
    0% {
        scale: 0.1;
    }

    75% {
        scale: 1.5;
    }

    100% {
        scale: 1;
    }
}